import './App.css'

import { default as GraphemeSplitter } from 'grapheme-splitter'
import { useEffect, useState } from 'react'

import SolutionsModal from './bot/SolutionsModal'
import SpotleBot, { bot } from './bot/SpotleBot'
import { AlertContainer } from './components/alerts/AlertContainer'
import {
  recordGaEvent,
  recordGuessGaEvent,
  recordWinLossGaEvent,
} from './components/alerts/Analytics'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutUsModal } from './components/modals/AboutUsModal'
import { AnnouncementsModal } from './components/modals/AnnouncementsModal'
import { CustomGameModal } from './components/modals/CustomGameModal'
import { InfoModal } from './components/modals/InfoModal'
import { MigrateStatsModal } from './components/modals/MigrateStatsModal'
import { NewGameModeModal } from './components/modals/NewGameModeModal'
import { ResetModal } from './components/modals/ResetModal'
import { StatsModal } from './components/modals/StatsModal'
import { CoSpotleGameModal } from './components/modals/cospotle/CoSpotleGameModal'
import { GameSettingsModal } from './components/modals/settings/GameSettingsModal'
import {
  GamesModal,
  LETTERS,
  MODES,
} from './components/modals/settings/GamesModal'
import { LanguageModal } from './components/modals/settings/LanguageModal'
import { SettingsModal } from './components/modals/settings/SettingsModal'
import { ThemeSettingsModal } from './components/modals/settings/ThemeSettingsModal'
import {
  getShortnedDefinitionText,
  recalcDefinitionAPI,
} from './components/navbar/Dictionary'
import { Footbar } from './components/navbar/Footbar'
import { Navbar } from './components/navbar/Navbar'
import { Language } from './constants/language'
import {
  APRIL_FOOLS_DATE,
  DISCOURAGE_INAPP_BROWSERS,
  LONG_ALERT_TIME_MS,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  WELCOME_INFO_MODAL_MS,
} from './constants/settings'
import { TranslationKey } from './constants/strings'
import { useAlert } from './context/AlertContext'
import {
  getLossMessage,
  getPageTitle,
  getTieMessage,
  getTranslation,
  getTranslationWithInfo,
  getWinMessage,
  getWrongSpotMessage,
} from './context/messages'
import {
  checkFakeSolution,
  fakeGuessUsed,
  getAprilFoolsFakeSolutions,
} from './lib/aprilFools'
import { isInAppBrowser } from './lib/browser'
import {
  addGuess,
  endGame,
  getFlagEmoji,
  getGuess,
  isChallengeModeGameDone,
  registerNewGame,
  resetChallengeModeGame,
  setCoSpotleWonLostTieStatus,
  winImpossible,
  winsNeededForNextLevel,
} from './lib/cospotle'
import { CountUpMonths } from './lib/countdown/CountUpMonths'
import { CountdownSeconds } from './lib/countdown/CountdownSeconds'
import {
  StoredGameState,
  decCounter,
  deleteStoredCustomCode,
  getHasSeenAnnouncements,
  getHasSeenNewGameModeInfo,
  getLanguage,
  getSpeedyStartTime,
  getStoredBotAssistedMode,
  getStoredBotChallengeMode,
  getStoredBotMode,
  getStoredCharObj,
  getStoredCoSpotleStatuses,
  getStoredCurrentRemainingSolutions,
  getStoredCustomUsername,
  getStoredDarkMode,
  getStoredGameDate,
  getStoredGridHardMode,
  getStoredHardMode,
  getStoredHiddenLetterMode,
  getStoredIsHighContrastMode,
  getStoredMyTurn,
  getStoredNumberOfGames,
  getStoredNumberOfRows,
  getStoredOpponent,
  getStoredOpponentCountry,
  getStoredParallelMode,
  getStoredPlainCoSpotle,
  getStoredRandomMode,
  getStoredShownAprilFools,
  getStoredSpyfallMode,
  getStoredStatuses,
  getStoredTeamMode,
  getStoredUsername,
  getWinsNeeded,
  handleStoredCoSpotleCustomCode,
  incCounter,
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setHasSeenAnnouncements,
  setHasSeenNewGameModeInfo,
  setLanguage,
  setSpeedyStartTime,
  setStoredBotAssistedMode,
  setStoredBotChallengeMode,
  setStoredBotMode,
  setStoredCharObj,
  setStoredCoSpotleStatuses,
  setStoredCustomInfo,
  setStoredCustomUsername,
  setStoredDarkMode,
  setStoredGameDate,
  setStoredGridHardMode,
  setStoredGuessDate,
  setStoredHardMode,
  setStoredHiddenLetterMode,
  setStoredIsHighContrastMode,
  setStoredMyTurn,
  setStoredNumberOfGames,
  setStoredNumberOfRows,
  setStoredOpponent,
  setStoredOpponentCountry,
  setStoredParallelMode,
  setStoredRandomMode,
  setStoredShownAprilFools,
  setStoredSpyfallMode,
  setStoredStatuses,
  setStoredTeamMode,
  setTitle,
  setWinsNeeded,
} from './lib/localStorage'
import {
  addSpeedyResult,
  addSpeedyTimePenalty,
  decSpeedyNumberOfGames,
  hasSpeedyGameStarted,
  incSpeedyNumberOfGames,
  isSpeedyGameDone,
  resetSpeedyGame,
} from './lib/speedy'
import {
  Result,
  addStatsForLostGame,
  addStatsForWonGame,
  addTieStatsForCompletedGame,
  loadStats,
} from './lib/stats'
import {
  CharStatus,
  getEasyGuessStatuses,
  getGuessStatuses,
  getSpecialBackground,
  setKeyStatuses,
} from './lib/statuses'
import {
  findFirstUnusedReveal,
  getFirstLetter,
  getIndex,
  getNewGameDate,
  getRandomGuesses,
  getSolution,
  isWordInWordList,
  shuffleGuesses,
  unicodeLength,
} from './lib/words'

var isUnlimitedMode = false
export function getIsUnlimitedMode(): boolean {
  return isUnlimitedMode
}

var horsle = false
export function getIsHorsle(): boolean {
  return horsle
}

var staircase = false
export function getIsStaircase(): boolean {
  return staircase
}

var lazy = false
export function getIsLazy(): boolean {
  return lazy
}

var taytay = false
export function getIsTaytay(): boolean {
  return taytay
}

var showSpotleBot = false
export function getIsShowSpotleBot(): boolean {
  return showSpotleBot
}

export function setShowSpotleBot(showBot: boolean) {
  showSpotleBot = showBot
}

var speedy = false
export function getIsSpeedy(): boolean {
  return speedy
}

var scalizzi = false
export function getIsScalizzi(): boolean {
  return scalizzi
}

var impossible = false
export function isImpossible(): boolean {
  return impossible
}

var coSpotle = false
export function isCoSpotle(): boolean {
  return coSpotle
}

var custom = false
export function isCustom(): boolean {
  return custom
}

var ginjanner = false
export function isGinJanner(): boolean {
  return ginjanner
}

var sixLetter = false
export function isSixLetter(): boolean {
  return sixLetter
}

function App(props: {
  isUnlimitedMode: boolean
  language: Language
  horsle: boolean
  staircase: boolean
  lazy: boolean
  taytay: boolean
  speedy: boolean
  scalizzi: boolean
  impossible: boolean
  coSpotle: boolean
  custom: boolean
  ginjanner: boolean
  sixLetter: boolean
  path: string
}) {
  isUnlimitedMode = props.isUnlimitedMode
  horsle = props.horsle
  staircase = props.staircase
  lazy = props.lazy
  taytay = props.taytay
  speedy = props.speedy
  scalizzi = props.scalizzi
  impossible = props.impossible
  coSpotle = props.coSpotle
  custom = props.custom
  ginjanner = props.ginjanner
  sixLetter = props.sixLetter

  //here just in case a player accesses the page directly
  //without using the settings to switch language
  if (typeof props.language !== 'undefined') {
    setLanguage(props.language)
  }

  setTitle(getPageTitle(props.path))

  if (isCustom()) {
    setStoredCustomInfo(window.location.href)
  }

  const {
    showError: showErrorAlert,
    showSuccess: showSuccessAlert,
    showTie: showTieAlert,
  } = useAlert()
  const [currentGuess, setCurrentGuess] = useState(
    getFirstLetter(getStoredGameDate(), 0, 0)
  )
  const [isGameWon, setIsGameWon] = useState(false)
  const [isFireworks, setIsFireworks] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isNormalStatuses, setIsNormalStatuses] = useState(true)
  const handleIsNormalStatuses = (newNormal: boolean) => {
    setIsNormalStatuses(newNormal)
  }
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false)
  const [isGamesModalOpen, setIsGamesModalOpen] = useState(false)
  const [isGameSettingsModalOpen, setIsGameSettingsModalOpen] = useState(
    getIsSpeedy() && isUnlimitedMode
  )
  const [isThemeSettingsModalOpen, setIsThemeSettingsModalOpen] =
    useState(false)
  const [isResetModalOpen, setIsResetModalOpen] = useState(false)
  const [isNewGameModeInfoModalOpen, setIsNewGameModeInfoModalOpen] =
    useState(false)
  const [isAnnouncementsModalOpen, setIsAnnouncementsModalOpen] =
    useState(false)
  const [isContactInfoModalOpen, setIsContactInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(true)
  const [isMigrateStatsModalOpen, setIsMigrateStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(getStoredDarkMode())
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [solutionIndex, setIndex] = useState(getIndex(getStoredGameDate()))
  const [solution, setSolution] = useState(getSolution(solutionIndex))
  const [numberOfGames, setNumberOfGames] = useState(getStoredNumberOfGames())
  function getAppNumberOfGames() {
    return numberOfGames
  }
  const [numberOfRows, setNumberOfRows] = useState(getStoredNumberOfRows)
  function getNumberOfRows() {
    return numberOfRows
  }
  const [username, setUsername] = useState(getStoredUsername())
  const [opponent, setOpponent] = useState(getStoredOpponent())
  const [myTurn, setMyTurn] = useState(getStoredMyTurn())
  const [isCoSpotleGameModalOpen, setIsCoSpotleGameModalOpen] = useState(
    isCoSpotle() &&
      props.path.includes('/cospotle') &&
      (!props.path.includes('/cospotle/bot') || getStoredOpponent() === '')
  )
  setStoredBotMode(props.path.includes('/cospotle/bot'))
  setStoredBotChallengeMode(
    props.path.includes('/cospotle/bot/challenge') ||
      props.path.includes('/cospotle/bot/parallel/challenge')
  )
  setStoredTeamMode(props.path.includes('/cospotle/team'))
  setStoredParallelMode(
    props.path.includes('/cospotle/parallel') ||
      props.path.includes('/cospotle/bot/parallel')
  )
  const [customUsername, setCustomUsername] = useState(
    getStoredCustomUsername()
  )
  const [isCustomGameModalOpen, setIsCustomGameModalOpen] = useState(
    isCustom() && window.location.href.endsWith('/custom')
  )
  const [coSpotleGuesses, setCoSpotleGuesses] = useState<string[]>(() => {
    if (!isCoSpotle()) {
      return []
    }

    const loaded = loadGameStateFromLocalStorage(true)
    if (
      loaded == null ||
      loaded.guesses === null ||
      loaded?.solution !== solution
    ) {
      return []
    }

    return loaded!.guesses
  })

  function handleIncNumberOfGames() {
    if (isSpeedyGameDone() || hasSpeedyGameStarted()) {
      showErrorAlert(getTranslation(TranslationKey.SPEEDY_SETTINGS))
    } else {
      incSpeedyNumberOfGames()
      setNumberOfGames(getStoredNumberOfGames())
    }
  }

  function handleDecNumberOfGames() {
    if (isSpeedyGameDone() || hasSpeedyGameStarted()) {
      showErrorAlert(getTranslation(TranslationKey.SPEEDY_SETTINGS))
    } else {
      decSpeedyNumberOfGames()
      setNumberOfGames(getStoredNumberOfGames())
    }
  }

  function handleIncNumberOfRows() {
    if (isImpossible() && (isGameWon || isGameLost || guesses.length === 0)) {
      const numberOfRows = getStoredNumberOfRows()
      if (numberOfRows >= 5) {
        setNumberOfRows(1)
        setStoredNumberOfRows(1)
        return onResetGame(true)
      }

      setStoredNumberOfRows(numberOfRows + 1)
      setNumberOfRows(getStoredNumberOfRows())

      onResetGame(true)
    } else {
      showErrorAlert(getTranslation(TranslationKey.IMPOSSIBLE_SETTINGS))
    }
  }

  function handleDecNumberOfRows() {
    if (isImpossible() && (isGameWon || isGameLost || guesses.length === 0)) {
      const numberOfRows = getStoredNumberOfRows()
      if (numberOfRows <= 1) {
        setNumberOfRows(5)
        setStoredNumberOfRows(5)
        return onResetGame(true)
      }

      setStoredNumberOfRows(numberOfRows - 1)
      setNumberOfRows(getStoredNumberOfRows())

      onResetGame(true)
    } else {
      showErrorAlert(getTranslation(TranslationKey.IMPOSSIBLE_SETTINGS))
    }
  }

  const isWinningWord = (word: string) => {
    return solution === word
  }
  const [isRevealing, setIsRevealing] = useState(false)

  const [stats, setStats] = useState(() => loadStats())

  const [pageNumber, setPageNumber] = useState(0)
  const [pageType, setPageType] = useState(MODES.NONE)
  const [letterMode, setLetterMode] = useState(LETTERS.NONE)
  const [botPageNumber, setBotPageNumber] = useState(-1)
  const [solutionsPageNumber, setSolutionsPageNumber] = useState(0)
  const [showSolutionsRemaining, setShowSolutionsRemaining] = useState(false)

  const [isHardMode, setIsHardMode] = useState(getStoredHardMode())
  const [isGridHardMode, setIsGridHardMode] = useState(getStoredGridHardMode())
  const [isHiddenLetterMode, setIsHiddenLetterMode] = useState(
    getStoredHiddenLetterMode()
  )
  const [isBotAssistedMode, setIsBotAssistedMode] = useState(
    getStoredBotAssistedMode()
  )
  const [isRandomMode, setIsRandomMode] = useState(getStoredRandomMode())
  const [isSpyfallMode, setIsSpyfallMode] = useState(getStoredSpyfallMode())

  const [statuses, setStatuses] = useState(
    getStoredStatuses().size === 0 && isGridHardMode
      ? getEasyGuessStatuses(
          getStoredGameDate(),
          isGridHardMode,
          solution.length,
          isHiddenLetterMode
        )
      : getStoredStatuses()
  )
  const [coSpotleStatuses, setCoSpotleStatuses] = useState(
    getStoredCoSpotleStatuses().size === 0 && isGridHardMode
      ? getEasyGuessStatuses(
          getStoredGameDate(),
          isGridHardMode,
          solution.length,
          isHiddenLetterMode
        )
      : getStoredCoSpotleStatuses()
  )

  const [charObj, setCharObj] = useState(getStoredCharObj())

  const resetLazyGame = (firstTime?: boolean) => {
    const newGameIndex = getIndex(getStoredGameDate())

    if (isSpyfallMode) {
      const newGameSolution = getSolution(newGameIndex)
      var possibleGuesses = shuffleGuesses([
        'PZAZZ',
        'XYLYL',
        'QAJAQ',
        'ZIZIT',
        'BOBBY',
        'MOMMY',
        'MUMMY',
        'MAMMY',
        'DADDY',
        'FUFFY',
        'MAMMA',
        'PIZZA',
        'SASSY',
        'DIZZY',
        'FIZZY',
        'FLUFF',
        'SAVVY',
        'POPPY',
        'TATTY',
        'GRRRL',
        'IGLOO',
      ])

      if (isSixLetter()) {
        shuffleGuesses([
          'QAJAQS',
          'ZIZITS',
          'MAMMAS',
          'PIZZAS',
          'FLUFFS',
          'GRRRLS',
          'IGLOOS',
        ])
      }

      const indexSolutions = possibleGuesses.indexOf(newGameSolution)
      if (indexSolutions !== -1) {
        possibleGuesses.splice(indexSolutions, 1)
      }

      return resetLazyGameWithGuesses(
        newGameIndex,
        possibleGuesses.slice(0, 5),
        firstTime
      )
    } else if (isRandomMode) {
      return resetLazyGameWithGuesses(
        newGameIndex,
        getRandomGuesses(getStoredGameDate()),
        firstTime
      )
    }

    //bot assisted
    return resetLazyGameWithGuesses(newGameIndex, undefined, firstTime)
  }

  const resetLazyGameWithGuesses = (
    newGameIndex: number,
    tempGuesses?: string[],
    firstTime?: boolean
  ) => {
    const newGameSolution = getSolution(newGameIndex)

    if (typeof tempGuesses === 'undefined') {
      tempGuesses = bot(
        getStoredHardMode(),
        getStoredGridHardMode(),
        getStoredHiddenLetterMode(),
        getStoredGameDate(),
        getSolution(getIndex(getStoredGameDate()))
      )[0]
    }

    const indexSolutions = tempGuesses.indexOf(newGameSolution)
    if (indexSolutions !== -1) {
      tempGuesses.splice(indexSolutions, 1)
    } else {
      tempGuesses.splice(5, 1)
    }

    var easyStatuses = getEasyGuessStatuses(
      getStoredGameDate(),
      isGridHardMode,
      solution.length,
      isHiddenLetterMode
    )

    for (let idx = 0; idx < tempGuesses.length; idx++) {
      easyStatuses = getGuessStatuses(
        idx,
        newGameSolution,
        tempGuesses,
        easyStatuses,
        getStoredGameDate(),
        isHiddenLetterMode
      )
    }

    var tempCharObj: { [key: string]: CharStatus } = {}

    easyStatuses.forEach((_, key) => {
      if (tempGuesses!.length > key) {
        setKeyStatuses(easyStatuses.get(key)!, tempGuesses![key], tempCharObj)
      }
    })

    //can be undefined
    if (firstTime !== true) {
      setGuesses(tempGuesses)
    }
    setStatuses(easyStatuses)
    setStoredStatuses(easyStatuses)
    setStoredCharObj(tempCharObj)
    setCharObj(tempCharObj)
    setCurrentGuess(getFirstLetter(getStoredGameDate(), tempGuesses.length, 0))

    return tempGuesses
  }

  const initialAprilFoolsMessage = (date: Date) => {
    if (
      date.getDate() !== APRIL_FOOLS_DATE.getDate() ||
      date.getMonth() !== APRIL_FOOLS_DATE.getMonth() ||
      getIsUnlimitedMode()
    ) {
      return
    }

    showTieAlert(
      'The solution is: ' +
        getAprilFoolsFakeSolutions(
          [],
          isHardMode,
          isGridHardMode,
          isHiddenLetterMode,
          statuses,
          currentGuess
        )[0],
      { persist: true }
    )
  }

  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage(false)
    if (loaded?.solution !== solution) {
      if (getIsLazy()) {
        resetSpeedyGame()
        setSolution(getSolution(getIndex(getStoredGameDate())))
        return resetLazyGame(true)
      } else {
        if (getIsSpeedy()) {
          resetSpeedyGame()
          setSolution(getSolution(getIndex(getStoredGameDate())))
        }

        setStoredStatuses(
          getEasyGuessStatuses(
            getStoredGameDate(),
            isGridHardMode,
            solution.length,
            isHiddenLetterMode
          )
        )
        setStatuses(getStoredStatuses())
        setStoredCharObj({})
        setCharObj({})
        initialAprilFoolsMessage(getStoredGameDate())
        return []
      }
    }

    if (loaded.guesses.length === 0) {
      initialAprilFoolsMessage(getStoredGameDate())
    }
    setWonLostTieStatus(loaded.guesses)

    if (isGinJanner()) {
      if (
        loaded.guesses.length >= MAX_CHALLENGES - 1 ||
        isGameLost ||
        isGameWon
      ) {
        setCurrentGuess('')
      } else {
        setCurrentGuess(
          getFirstLetter(getStoredGameDate(), loaded.guesses.length, 0)
        )
      }
    }
    return loaded.guesses
  })

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal
    if (
      !loadGameStateFromLocalStorage(false) &&
      !isUnlimitedMode &&
      !isCustom() &&
      !getIsSpeedy() &&
      !getIsLazy() &&
      !getIsShowSpotleBot()
    ) {
      recordGaEvent('new_player')

      setTimeout(() => {
        setIsInfoModalOpen(true)
        setHasSeenNewGameModeInfo(true)
        setHasSeenAnnouncements(true)
      }, WELCOME_INFO_MODAL_MS)
    }
  })

  useEffect(() => {
    if (
      loadGameStateFromLocalStorage(false) &&
      !isUnlimitedMode &&
      !isCustom()
    ) {
      if (!getHasSeenAnnouncements()) {
        setTimeout(() => {
          setHasSeenAnnouncements(true)
          setIsAnnouncementsModalOpen(true)
          setHasSeenNewGameModeInfo(true)
        }, WELCOME_INFO_MODAL_MS * 2)
      } else if (!getHasSeenNewGameModeInfo()) {
        setTimeout(() => {
          setHasSeenNewGameModeInfo(true)
          setIsNewGameModeInfoModalOpen(true)
          setHasSeenAnnouncements(true)
        }, WELCOME_INFO_MODAL_MS * 2)
      }
    }
  })

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(
        getTranslation(TranslationKey.DISCOURAGE_INAPP_BROWSER_TEXT),
        {
          persist: false,
          durationMs: 7000,
        }
      )
  }, [showErrorAlert])

  //guesses not complete because of the current guess thingy
  function setWonLostTieStatus(playerGuesses: string[]): Result {
    if (isCoSpotle()) {
      return setCoSpotleWonLostTieStatus(
        playerGuesses,
        coSpotleGuesses,
        setIsGameLost,
        setIsGameWon,
        solution,
        0
      )
    }
    if (playerGuesses.includes(solution)) {
      setIsGameWon(true)

      return Result.WIN
    } else if (playerGuesses.length === MAX_CHALLENGES) {
      setIsGameLost(true)

      return Result.LOSS
    }

    return Result.EMPTY
  }

  function addGameStats(playerGuesses: string[]) {
    const result = setWonLostTieStatus(playerGuesses)
    if (result === Result.WIN) {
      addSpeedyGame(false)
      recordWinLossGaEvent('game_win', playerGuesses.length)
      setStats(addStatsForWonGame(stats, playerGuesses.length))
    } else if (result === Result.LOSS) {
      addSpeedyGame(true)
      recordWinLossGaEvent('game_loss', playerGuesses.length)
      setStats(addStatsForLostGame(stats))
    } else if (result === Result.TIE) {
      recordWinLossGaEvent('game_tie', playerGuesses.length)
      setStats(addTieStatsForCompletedGame(stats))
    }

    if (result !== Result.EMPTY) {
      recalcDefinitionAPI(solution)
    }

    return result
  }

  function getGuessUseEffectTime() {
    if (getStoredParallelMode()) {
      return 10
    }

    if (myTurn) {
      return 1000000
    }

    if (getStoredBotMode()) {
      return 2500
    }

    return 300
  }

  const guessEffectTime = getGuessUseEffectTime()

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        isCoSpotle() &&
        username !== '' &&
        opponent !== '' &&
        ((!myTurn && !isGameLost && !isGameWon) || getStoredParallelMode())
      ) {
        //making sure that on parallel mode the bot only guesses once per player guess
        if (
          getStoredParallelMode() &&
          getStoredBotMode() &&
          (guesses.length <= coSpotleGuesses.length || isRevealing)
        ) {
          return
        }

        getGuess(
          username,
          setCoSpotleGuesses,
          setCoSpotleStatuses,
          guesses,
          coSpotleGuesses,
          setIsGameWon,
          stats,
          setStats
        )
      }
    }, guessEffectTime)
    return () => clearInterval(interval)
  }, [
    username,
    opponent,
    isGameLost,
    isGameWon,
    setIsGameWon,
    setIsGameLost,
    guesses,
    coSpotleGuesses,
    stats,
    myTurn,
    guessEffectTime,
    isRevealing,
  ])

  useEffect(() => {
    if (
      !isCoSpotle() ||
      getStoredParallelMode() ||
      coSpotleGuesses.length <= guesses.length
    ) {
      return
    } else {
      setIsRevealing(true)
      // turn this back off after all
      // chars have been revealed
      setTimeout(() => {
        setIsRevealing(false)
      }, REVEAL_TIME_MS * solution.length)
      guesses.push(coSpotleGuesses[coSpotleGuesses.length - 1])

      if (solution === guesses[guesses.length - 1]) {
        if (
          !isUndefined(statuses.get(guesses.length - 1)) &&
          containsUndefined(statuses.get(guesses.length - 1)!)
        ) {
          const guessStatuses = statuses.get(guesses.length - 1)!
          for (let index = 0; index < guessStatuses.length; index++) {
            if (
              typeof guessStatuses[index] === 'undefined' ||
              guessStatuses[index] === null
            ) {
              guessStatuses[index] = 'correct'
            }
          }
          setTimeout(() => {
            const correctArray: CharStatus[] = []
            for (var i = 0; i < solution.length; i++) {
              correctArray.push('correct')
            }
            statuses.set(guesses.length - 1, correctArray)

            setStoredStatuses(statuses)
            //this is needed because the call below would be done before all the rows are set to correct in the UI
            //meaning that the rendering would spoil the result
          }, WELCOME_INFO_MODAL_MS)
        } else {
          const correctArray: CharStatus[] = []
          for (var i = 0; i < solution.length; i++) {
            correctArray.push('correct')
          }
          statuses.set(guesses.length - 1, correctArray)
        }
      } else {
        statuses.set(
          guesses.length - 1,
          getGuessStatuses(
            guesses.length - 1,
            solution,
            guesses,
            statuses,
            getStoredGameDate(),
            getStoredHiddenLetterMode()
          ).get(guesses.length - 1)!
        )
      }

      setKeyStatuses(
        statuses.get(guesses.length - 1)!,
        guesses[guesses.length - 1],
        charObj
      )

      setStoredStatuses(statuses)
      setStoredCharObj(charObj)

      if (
        guesses.length === MAX_CHALLENGES &&
        !(isCoSpotle() && getStoredPlainCoSpotle())
      ) {
        setIsGameLost(true)
        setStats(addStatsForLostGame(stats))
      } else {
        setMyTurn(true)
        setStoredMyTurn(true)
      }

      // to make sure that if the player refreshes the page in any of the bot modes
      // it can continue to play without losing info or gaining an advantage
      setGuesses(coSpotleGuesses)
    }
  }, [solution, coSpotleGuesses, guesses, charObj, statuses, stats])

  useEffect(() => {
    if (!isGameLost && !isGameWon && isCoSpotle()) {
      if (getStoredParallelMode()) {
        if (getStoredBotMode()) {
          if (guesses.length === coSpotleGuesses.length) {
            if (guesses.includes(solution)) {
              if (coSpotleGuesses.includes(solution)) {
                setIsGameLost(true)
                setIsGameWon(true)
                setStats(addTieStatsForCompletedGame(stats))
              } else {
                setIsGameWon(true)
                setStats(addStatsForWonGame(stats, guesses.length))
              }
            } else if (coSpotleGuesses.includes(solution)) {
              setIsGameLost(true)
              setStats(addStatsForLostGame(stats))
            } else if (
              guesses.length === MAX_CHALLENGES &&
              coSpotleGuesses.length === MAX_CHALLENGES
            ) {
              setIsGameLost(true)
              setIsGameWon(true)
              setStats(addTieStatsForCompletedGame(stats))
            }
          }
        } else {
          if (coSpotleGuesses.includes(solution)) {
            setIsGameLost(true)
            setStats(addStatsForLostGame(stats))
          } else if (
            !guesses.includes(solution) &&
            guesses.length === MAX_CHALLENGES &&
            coSpotleGuesses.length === MAX_CHALLENGES
          ) {
            //both lost
            setIsGameLost(true)
            setIsGameWon(true)
            setStats(addTieStatsForCompletedGame(stats))
          }
        }
      } else if (getStoredPlainCoSpotle()) {
        if (coSpotleGuesses.includes(solution)) {
          setIsGameLost(true)
          setStats(addStatsForLostGame(stats))
        }
      } else if (getStoredTeamMode()) {
        if (coSpotleGuesses.includes(solution)) {
          setIsGameWon(true)
          setStats(addStatsForWonGame(stats, coSpotleGuesses.length))
        } else if (coSpotleGuesses.length === MAX_CHALLENGES) {
          setIsGameLost(true)
          setStats(addStatsForLostGame(stats))
        }
      }
    }
  }, [coSpotleGuesses, guesses, isGameLost, isGameWon, solution, stats])

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(
        getTranslation(TranslationKey.DISCOURAGE_INAPP_BROWSER_TEXT),
        {
          persist: false,
          durationMs: 7000,
        }
      )
  }, [showErrorAlert])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    setStoredDarkMode(isDark)
  }

  const handleCustomUsername = (customUsername: string) => {
    setCustomUsername(customUsername)
    setStoredCustomUsername(customUsername)
  }

  const handleCustomSolution = (customSolution: string) => {
    setStoredCustomInfo(customSolution)
  }

  const handleHardMode = (isHard: boolean) => {
    if (
      guesses.length === 0 ||
      (getIsLazy() && !getStoredSpyfallMode()) ||
      isCoSpotle()
    ) {
      setIsHardMode(isHard)
      setStoredHardMode(isHard)
    } else {
      showErrorAlert(getTranslation(TranslationKey.HARD_MODE_ALERT_MESSAGE))
    }
  }

  const handleGridHardMode = (isHard: boolean) => {
    if (isGameWon || isGameLost || getIsLazy() || isCoSpotle()) {
      setIsGridHardMode(isHard)
      setStoredGridHardMode(isHard)

      if (!isCoSpotle()) {
        showSuccessAlert(
          getTranslation(TranslationKey.GRID_HARD_MODE_FUTURE_UPDATE_MESSAGE)
        )
      }
    } else {
      showErrorAlert(
        getTranslation(TranslationKey.GRID_HARD_MODE_ALERT_MESSAGE)
      )
    }
  }

  const handleHiddenLetterMode = (isHard: boolean) => {
    if (guesses.length === 0 || getIsLazy() || isCoSpotle()) {
      setIsHiddenLetterMode(isHard)
      setStoredHiddenLetterMode(isHard)
    } else {
      showErrorAlert(getTranslation(TranslationKey.HIDDEN_LETTER_ALERT_MESSAGE))
    }
  }

  const handleBotAssistedMode = (useMode: boolean) => {
    //needs to be at the end of a game and at least one mode must be on
    if (isGameWon || isGameLost) {
      if (useMode || isRandomMode || isSpyfallMode) {
        setIsBotAssistedMode(useMode)
        setStoredBotAssistedMode(useMode)
        setIsRandomMode(!useMode)
        setStoredRandomMode(!useMode)
        setIsSpyfallMode(!useMode)
        setStoredSpyfallMode(!useMode)
        showSuccessAlert(
          getTranslation(TranslationKey.LAZY_FUTURE_UPDATE_MESSAGE)
        )
      } else {
        showErrorAlert(getTranslation(TranslationKey.LAZY_MODE_ALERT_MESSAGE2))
      }
    } else {
      showErrorAlert(getTranslation(TranslationKey.LAZY_MODE_ALERT_MESSAGE))
    }
  }

  const handleRandomMode = (useMode: boolean) => {
    if (isGameWon || isGameLost) {
      if (useMode || isBotAssistedMode || isSpyfallMode) {
        setIsRandomMode(useMode)
        setStoredRandomMode(useMode)
        setIsBotAssistedMode(!useMode)
        setStoredBotAssistedMode(!useMode)
        setIsSpyfallMode(!useMode)
        setStoredSpyfallMode(!useMode)
        showSuccessAlert(
          getTranslation(TranslationKey.LAZY_FUTURE_UPDATE_MESSAGE)
        )
      } else {
        showErrorAlert(getTranslation(TranslationKey.LAZY_MODE_ALERT_MESSAGE2))
      }
    } else {
      showErrorAlert(getTranslation(TranslationKey.LAZY_MODE_ALERT_MESSAGE))
    }
  }

  const handleSpyfallMode = (useMode: boolean) => {
    if (isGameWon || isGameLost) {
      if (useMode || isRandomMode || isBotAssistedMode) {
        setIsSpyfallMode(useMode)
        setStoredSpyfallMode(useMode)
        setIsBotAssistedMode(!useMode)
        setStoredBotAssistedMode(!useMode)
        setIsRandomMode(!useMode)
        setStoredRandomMode(!useMode)
        showSuccessAlert(
          getTranslation(TranslationKey.LAZY_FUTURE_UPDATE_MESSAGE)
        )
        setIsHardMode(false)
        setStoredHardMode(false)
      } else {
        showErrorAlert(getTranslation(TranslationKey.LAZY_MODE_ALERT_MESSAGE2))
      }
    } else {
      showErrorAlert(getTranslation(TranslationKey.LAZY_MODE_ALERT_MESSAGE))
    }
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution }, false)
  }, [guesses, solution])

  useEffect(() => {
    const state: StoredGameState = { guesses, solution }
    state.guesses = coSpotleGuesses
    state.solution = solution

    saveGameStateToLocalStorage(state, true)
  }, [guesses, coSpotleGuesses, solution])

  useEffect(() => {
    if (!getIsSpeedy()) {
      var delayMs = REVEAL_TIME_MS * solution.length
      if (getStoredParallelMode()) {
        delayMs = REVEAL_TIME_MS
      }

      if (isGameLost && isGameWon) {
        showTieAlert(getTieMessage(getStoredGameDate()), {
          onClose: () => {
            if (!isCoSpotleGameModalOpen) {
              setIsStatsModalOpen(true)
              setIsButtonEnabled(true)
            }
            setStoredGuessDate(undefined)
          },
        })
      } else if (isGameWon) {
        setTimeout(() => {
          setIsFireworks(true)
          setStoredGuessDate(undefined)
        }, delayMs)

        showSuccessAlert(getWinMessage(getStoredGameDate()), {
          delayMs,
          onClose: () => {
            if (!isCoSpotleGameModalOpen) {
              setIsStatsModalOpen(true)
              setIsButtonEnabled(true)
            }
            setStoredGuessDate(undefined)
          },
        })
      } else if (isGameLost) {
        showErrorAlert(getLossMessage(getStoredGameDate(), solution), {
          persist: !isCoSpotle(),
          delayMs: delayMs,
        })

        setTimeout(() => {
          if (!isCoSpotleGameModalOpen) {
            setIsStatsModalOpen(true)
            setIsButtonEnabled(true)
          }
          setStoredGuessDate(undefined)
        }, delayMs)
      }
    }
  }, [
    isGameWon,
    isGameLost,
    showSuccessAlert,
    solution,
    showErrorAlert,
    isCoSpotleGameModalOpen,
    showTieAlert,
  ])

  useEffect(() => {
    if (getIsSpeedy()) {
      if (isGameLost) {
        showErrorAlert(
          getTranslationWithInfo(TranslationKey.CORRECT_WORD_MESSAGE, solution),
          {
            persist: false,
          }
        )
      }

      if (isSpeedyGameDone()) {
        setIsFireworks(true)
        setIsStatsModalOpen(true)

        if (isGameWon) {
          showSuccessAlert(getWinMessage(getStoredGameDate()), {
            persist: false,
          })
        }
      }
    }
  }, [isGameWon, isGameLost, showSuccessAlert, solution, showErrorAlert])

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= solution.length &&
      (guesses.length < MAX_CHALLENGES || isCoSpotle()) &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  if (isCoSpotle()) {
    const value = handleStoredCoSpotleCustomCode(window.location.href)

    if (value) {
      resetCoSpotleGame(true)
    }
  }

  function resetCoSpotleGame(softReset?: boolean) {
    if (
      !getStoredBotMode() &&
      (customUsername === '' ||
        customUsername === undefined ||
        customUsername.length > 20)
    ) {
      setStoredCustomUsername('Temp')
      setCustomUsername('Temp')
    }

    if (
      ((isGameLost &&
        coSpotleGuesses[coSpotleGuesses.length - 1] !== solution) ||
        (isGameWon &&
          coSpotleGuesses[coSpotleGuesses.length - 1] === solution)) &&
      getStoredBotMode() &&
      getStoredBotChallengeMode() &&
      getStoredParallelMode()
    ) {
      // the counter is increased each game, so this will decrease it if the bot also lost/won
      // meaning that the game is considered null
      decCounter()
    }

    setCoSpotleGuesses([])
    setGuesses([])
    setStoredCoSpotleStatuses(
      getEasyGuessStatuses(
        getStoredGameDate(),
        isGridHardMode,
        solution.length,
        isHiddenLetterMode
      )
    )
    setCoSpotleStatuses(
      getEasyGuessStatuses(
        getStoredGameDate(),
        isGridHardMode,
        solution.length,
        isHiddenLetterMode
      )
    )
    if (!(softReset && getStoredBotMode())) {
      setOpponent('')
      setStoredOpponent('')
      setStoredOpponentCountry('')
    }

    if (getStoredBotMode() && getStoredBotChallengeMode()) {
      if (softReset !== undefined) {
        //when using the cospotle modal to start/continue the game,
        //do not increment the counter
        incCounter()
        setWinsNeeded(winsNeededForNextLevel())
      } else {
        //just in the case the player as never played before
        setStoredNumberOfGames(3)
        setNumberOfGames(3)
        setWinsNeeded(winsNeededForNextLevel())
        softReset = false
      }

      if (
        softReset === false ||
        isChallengeModeGameDone() ||
        getWinsNeeded() === 0 ||
        winImpossible()
      ) {
        setOpponent('')
        setStoredOpponent('')
        setStoredOpponentCountry('')

        //=== false to make sure we don't set the game to level 1 by mistake
        //level 1 is for new games only
        resetChallengeModeGame(
          showSuccessAlert,
          showErrorAlert,
          softReset === false
        )

        //setting this to false to make sure a new bot is chosen
        softReset = false
      }
    }

    setMyTurn(false)
    setStoredMyTurn(false)
    setStoredGuessDate(undefined)
    setIsGameLost(false)
    setIsGameWon(false)

    //TODO remove later
    setIsHardMode(false)
    setIsGridHardMode(false)
    setIsHiddenLetterMode(false)
    setStoredHardMode(false)
    setStoredGridHardMode(false)
    setStoredHiddenLetterMode(false)

    if (!softReset) {
      deleteStoredCustomCode()
    }

    if (username === '') {
      setUsername(getStoredUsername())
    }

    ;(async () => {
      endGame(username)

      await new Promise((f) => setTimeout(f, 1000))
      registerNewGame(
        username,
        setMyTurn,
        setOpponent,
        onResetGame,
        setIsCoSpotleGameModalOpen,
        customUsername,
        showSuccessAlert,
        softReset,
        0
      )
    })()
  }

  const onResetGame = (override?: boolean) => {
    if (
      ((getIsSpeedy() && !isSpeedyGameDone()) ||
        isGameWon ||
        isGameLost ||
        override) &&
      getIsUnlimitedMode()
    ) {
      setGuesses([])

      if (!isCoSpotle()) {
        setStoredGameDate(getNewGameDate(getIsUnlimitedMode()))
      }

      setStoredCharObj({})
      setCharObj({})
      setIsFireworks(false)
      setIsGameWon(false)
      setIsGameLost(false)
      setIsStatsModalOpen(false)
      setIsInfoModalOpen(false)
      setIsLanguageModalOpen(false)
      setIsGamesModalOpen(false)
      setIsMigrateStatsModalOpen(false)
      setIndex(getIndex(getStoredGameDate()))
      setSolution(getSolution(getIndex(getStoredGameDate())))
      setCurrentGuess(getFirstLetter(getStoredGameDate(), 0, 0))
      setStats(loadStats())
      setIsGridHardMode(getStoredGridHardMode())
      setIsHiddenLetterMode(getStoredHiddenLetterMode())
      setStoredStatuses(
        getEasyGuessStatuses(
          getStoredGameDate(),
          isGridHardMode,
          solution.length,
          getStoredHiddenLetterMode()
        )
      )
      setStatuses(getStoredStatuses())

      if (isCoSpotle() && getStoredParallelMode()) {
        setStoredCoSpotleStatuses(
          getEasyGuessStatuses(
            getStoredGameDate(),
            isGridHardMode,
            solution.length,
            isHiddenLetterMode
          )
        )
        setCoSpotleStatuses(getStoredCoSpotleStatuses())

        setCoSpotleGuesses([])
      }

      if (!getIsSpeedy() && !getStoredBotChallengeMode()) {
        showSuccessAlert(getTranslation(TranslationKey.NEW_GAME))
      }
    } else if (!getIsUnlimitedMode()) {
      const newSolution = getSolution(getIndex(getStoredGameDate()))
      setIsGridHardMode(getStoredGridHardMode())
      setIsHiddenLetterMode(getStoredHiddenLetterMode())

      const loaded = loadGameStateFromLocalStorage(false)
      if (loaded?.solution !== newSolution) {
        setIsGameWon(false)
        setIsGameLost(false)
        setGuesses([])
        setStoredGameDate(getNewGameDate(getIsUnlimitedMode()))
        setStoredCharObj({})
        setCharObj({})

        setStoredStatuses(
          getEasyGuessStatuses(
            getStoredGameDate(),
            isGridHardMode,
            solution.length,
            isHiddenLetterMode
          )
        )
        setStatuses(getStoredStatuses())
      } else {
        setGuesses(loaded?.guesses)
        setStatuses(getStoredStatuses())
        setCharObj(getStoredCharObj())

        //guesses were reset above
        setIsGameWon(guesses[guesses.length - 1] === loaded?.solution)
        setIsGameLost(guesses[guesses.length - 1] !== loaded?.solution)
      }
      setCurrentGuess(getFirstLetter(getStoredGameDate(), guesses.length, 0))
      setIsStatsModalOpen(false)
      setIsInfoModalOpen(false)
      setIsLanguageModalOpen(false)
      setIsGamesModalOpen(false)
      setIsMigrateStatsModalOpen(false)
      setIsFireworks(false)
      setIndex(getIndex(getStoredGameDate()))
      setSolution(newSolution)
      setStats(loadStats())

      if (!getIsSpeedy()) {
        showSuccessAlert(getTranslation(TranslationKey.NEW_GAME))
      }
    } else {
      showErrorAlert(getTranslation(TranslationKey.INCOMPLETE_GAME))
    }

    if (getIsHorsle()) {
      recalcDefinitionAPI(solution)
    }

    if (getIsLazy()) {
      resetLazyGame()
    }

    return
  }

  const isUndefined = (array: CharStatus[] | undefined): boolean => {
    if (typeof array === 'undefined') {
      return true
    }

    return false
  }

  const containsUndefined = (array: CharStatus[]): boolean => {
    for (let index = 0; index < array.length; index++) {
      if (typeof array[index] === 'undefined' || array[index] === null) {
        return true
      }
    }
    return false
  }

  const allHidden = (array: CharStatus[]): boolean => {
    for (let index = 0; index < array.length; index++) {
      if (array[index] !== 'hidden') {
        return false
      }
    }
    return true
  }

  const aprilFoolsMessages = (
    fakeSolution: string,
    midSolution: string,
    lastSolution: string
  ) => {
    const settings = {
      durationMs: 3000,
      delayMs: REVEAL_TIME_MS * solution.length,
    }
    var addDelay = true
    if (checkFakeSolution(0, fakeSolution, guesses, currentGuess)) {
      //user ignored solution message 1
      showErrorAlert("Why didn't you trust me???", settings)
    } else if (
      guesses.length > 0 &&
      !fakeGuessUsed(guesses, fakeSolution) &&
      currentGuess === fakeSolution
    ) {
      //user played a fake solution out of position for the first time
      showErrorAlert('LOL now you try it...', settings)
    } else if (checkFakeSolution(2, midSolution, guesses, currentGuess)) {
      //user ignored solution message 2
      showErrorAlert('TRUST ME!!!!', settings)
    } else if (
      !fakeGuessUsed(guesses, midSolution) &&
      currentGuess === midSolution &&
      guesses.includes(fakeSolution) &&
      guesses.length > 2
    ) {
      //user plays solution 2 after index 2 and has played the fake solution before too
      showErrorAlert('Seriously??', settings)
    } else if (checkFakeSolution(4, lastSolution, guesses, currentGuess)) {
      showErrorAlert('You are just hurting me now...', settings)
    } else if (
      fakeSolution !== currentGuess &&
      guesses.length === 1 &&
      getStoredShownAprilFools() &&
      !guesses.includes(currentGuess) &&
      !guesses.includes(fakeSolution)
    ) {
      //to add a delay when a non fake solution was played on the 2nd try
      //and where the 1st try is a non fake solution
      //this is just to avoid cancelling the delay
    } else {
      addDelay = false
    }

    var delay = 2000
    if (!addDelay) {
      //if no message is displayed above, then there's no need to delay the ones below
      delay = 0
    }

    if (guesses.length === 1) {
      showTieAlert('OK, I lied. The solution is: ' + midSolution, {
        delayMs: REVEAL_TIME_MS * solution.length + delay,
        persist: true,
      })
    } else if (guesses.length === 3) {
      showTieAlert('... just try ' + lastSolution, {
        delayMs: REVEAL_TIME_MS * solution.length + delay,
        persist: true,
      })
    }
  }

  const aprilFools = (date: Date) => {
    if (
      date.getDate() !== APRIL_FOOLS_DATE.getDate() ||
      date.getMonth() !== APRIL_FOOLS_DATE.getMonth() ||
      getIsUnlimitedMode() ||
      getIsSpeedy() ||
      getIsLazy()
    ) {
      wasAprilFools = false
      return
    }

    wasAprilFools = true

    const [fakeSolution, midSolution, lastSolution] =
      getAprilFoolsFakeSolutions(
        guesses,
        isHardMode,
        isGridHardMode,
        isHiddenLetterMode,
        statuses,
        currentGuess
      )

    if (
      ([fakeSolution, midSolution, lastSolution].includes(currentGuess) ||
        (guesses.length === 1 && !getStoredShownAprilFools())) &&
      !guesses.includes(currentGuess)
    ) {
      setStoredShownAprilFools(true)
      const correctGuessStatus = Object.assign(
        [],
        statuses.get(guesses.length)!
      )

      const guessStatuses = statuses.get(guesses.length)!
      for (let index = 0; index < guessStatuses.length; index++) {
        if (guessStatuses[index] !== 'hidden') {
          guessStatuses[index] = 'correct'
        }
      }

      setTimeout(() => {
        const correctArray: CharStatus[] = []
        for (var i = 0; i < solution.length; i++) {
          correctArray.push('correct')
        }
        statuses.set(guesses.length, correctArray)

        //this is needed because the call below would be done before all the rows are set to correct in the UI
        //meaning that the rendering would spoil the result
        setStoredStatuses(statuses)
      }, WELCOME_INFO_MODAL_MS)

      setTimeout(() => {
        setIsRevealing(true)
        setIsFireworks(true)
      }, REVEAL_TIME_MS * solution.length)
      ;(async () => {
        await new Promise((f) => setTimeout(f, 4000))

        statuses.set(guesses.length, correctGuessStatus)
        setStoredStatuses(statuses)
        setKeyStatuses(statuses.get(guesses.length)!, currentGuess, charObj)
        setStoredCharObj(charObj)

        if (guesses.length === 0) {
          showErrorAlert('Wait... WHAT???', { durationMs: 3000 })
        } else if (
          guesses.length === 1 &&
          ![fakeSolution, midSolution, lastSolution].includes(currentGuess)
        ) {
          showErrorAlert('AH AH', { durationMs: 3000 })
        } else if (guesses.length === 2) {
          showErrorAlert('AH AH AH AH Really???', { durationMs: 3000 })
        } else if (guesses.length === 4) {
          showErrorAlert(
            '... You should have known better by now LOLOLOLOLOL',
            { durationMs: 3000 }
          )
        }

        setIsFireworks(false)
        setIsRevealing(false)

        aprilFoolsMessages(fakeSolution, midSolution, lastSolution)
      })()
    } else {
      aprilFoolsMessages(fakeSolution, midSolution, lastSolution)
    }
  }

  var wasAprilFools = false

  const tiktokMessages = (currentGuess: string, solution: string) => {
    if (solution === currentGuess || getLanguage() !== Language.ENGLISH) {
      return
    }

    const delayMs = REVEAL_TIME_MS * solution.length

    if (currentGuess === 'gamer' || currentGuess === 'GAMER') {
      return showSuccessAlert('Are you Kenny Haller????', { delayMs })
    }

    if (currentGuess === 'lunar' || currentGuess === 'LUNAR') {
      return showSuccessAlert('Are you Michael DiCostanzo????', { delayMs })
    }

    if (currentGuess === 'ample' || currentGuess === 'AMPLE') {
      return showSuccessAlert('Are you Savannah????', { delayMs })
    }

    if (currentGuess === 'wharf' || currentGuess === 'WHARF') {
      return showSuccessAlert('Are you Michael DiCostanzo????', { delayMs })
    }

    if (currentGuess === 'beach' || currentGuess === 'BEACH') {
      return showSuccessAlert('Are you Bryn????', { delayMs })
    }
  }

  const addSpeedyGame = (loss: boolean) => {
    if (getIsSpeedy()) {
      incCounter()
      if (loss) {
        addSpeedyTimePenalty()
        setIsGameLost(true)
      } else {
        setIsGameWon(true)
      }
      if (!isSpeedyGameDone()) {
        return setTimeout(() => {
          onResetGame()
        }, 50)
      } else {
        addSpeedyResult()
      }
    }
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      if (isUnlimitedMode && !isCoSpotle()) {
        onResetGame()
      } else if (isCoSpotle()) {
        resetCoSpotleGame(true)
      }
      return
    }

    if (isCoSpotle()) {
      if (!getStoredMyTurn() && !getStoredParallelMode()) {
        setCurrentRowClass('jiggle')
        return showErrorAlert('Not your turn')
      }
    }

    if (!(unicodeLength(currentGuess) === solution.length)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(
        getTranslation(TranslationKey.NOT_ENOUGH_LETTERS_MESSAGE),
        {
          onClose: clearCurrentRowClass,
        }
      )
    }

    if (
      !isWordInWordList(currentGuess) &&
      (!isCustom() ||
        ((solution.length === 5 || solution.length === 6) &&
          solution !== currentGuess))
    ) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(
        getTranslation(TranslationKey.WORD_NOT_FOUND_MESSAGE),
        {
          onClose: clearCurrentRowClass,
        }
      )
    }

    // enforce hard mode - all guesses must contain all previously revealed letters
    if (isHardMode) {
      const firstMissingReveal = findFirstUnusedReveal(
        currentGuess,
        guesses,
        statuses,
        getStoredGameDate()
      )
      if (firstMissingReveal) {
        setCurrentRowClass('jiggle')
        return showErrorAlert(firstMissingReveal, {
          onClose: clearCurrentRowClass,
        })
      }
    }

    const winningWord = isWinningWord(currentGuess)
    if (isGinJanner() && !winningWord) {
      const firstLetter = getFirstLetter(getStoredGameDate(), guesses.length, 0)
      if (!currentGuess.startsWith(firstLetter)) {
        const message = getWrongSpotMessage(firstLetter, 1)

        setCurrentRowClass('jiggle')
        return showErrorAlert(message, {
          onClose: clearCurrentRowClass,
        })
      }
    }

    if (getIsSpeedy()) {
      if (getSpeedyStartTime() === 0) {
        resetSpeedyGame()
        setSpeedyStartTime(Math.floor(Date.now() / 1000))
      }
    }

    if (!getIsSpeedy() && !getStoredParallelMode()) {
      tiktokMessages(currentGuess, solution)

      setIsRevealing(true)
      // turn this back off after all
      // chars have been revealed
      setTimeout(() => {
        setIsRevealing(false)
      }, REVEAL_TIME_MS * solution.length)
    }

    if (
      unicodeLength(currentGuess) === solution.length &&
      (guesses.length < MAX_CHALLENGES || isCoSpotle()) &&
      !isGameWon
    ) {
      // this DOES NOT add the current guess to guesses, only creates a
      // temp array with the current guess at the end
      setGuesses([...guesses, currentGuess])
      setCurrentGuess(
        getFirstLetter(getStoredGameDate(), guesses.length + 1, 0)
      )

      if (winningWord) {
        if (isCoSpotle()) {
          if (
            getStoredParallelMode() ||
            coSpotleGuesses.length === guesses.length
          ) {
            addGuess(username, currentGuess, guesses.length)
            setMyTurn(false)
            setStoredMyTurn(false)
          }
        }
        recordGuessGaEvent('game_guess', currentGuess, guesses.length + 1)

        if (
          !isUndefined(statuses.get(guesses.length)) &&
          (allHidden(statuses.get(guesses.length)!) ||
            containsUndefined(statuses.get(guesses.length)!))
        ) {
          const guessStatuses = statuses.get(guesses.length)!
          for (let index = 0; index < guessStatuses.length; index++) {
            if (
              typeof guessStatuses[index] === 'undefined' ||
              guessStatuses[index] === null
            ) {
              guessStatuses[index] = 'correct'
            }
          }

          if (getIsSpeedy() || getStoredParallelMode()) {
            const correctArray: CharStatus[] = []
            for (var i = 0; i < solution.length; i++) {
              correctArray.push('correct')
            }
            statuses.set(guesses.length, correctArray)
            setStoredStatuses(statuses)

            setKeyStatuses(statuses.get(guesses.length)!, currentGuess, charObj)
            setStoredCharObj(charObj)
          } else {
            setTimeout(() => {
              const correctArray: CharStatus[] = []
              for (var i = 0; i < solution.length; i++) {
                correctArray.push('correct')
              }
              statuses.set(guesses.length, correctArray)

              //this is needed because the call below would be done before all the rows are set to correct in the UI
              //meaning that the rendering would spoil the result
              setStoredStatuses(statuses)
              setKeyStatuses(
                statuses.get(guesses.length)!,
                currentGuess,
                charObj
              )
              setStoredCharObj(charObj)
            }, WELCOME_INFO_MODAL_MS)

            recalcDefinitionAPI(solution)
          }

          if (getStoredParallelMode() && getStoredBotMode()) {
            // this is handled by the useEffect that is waiting for the bot to make it's final guess
            return
          }

          return addGameStats([...guesses, currentGuess])
        } else {
          const correctArray: CharStatus[] = []
          for (var k = 0; k < solution.length; k++) {
            correctArray.push('correct')
          }
          statuses.set(guesses.length, correctArray)
          setStoredStatuses(statuses)
        }

        setKeyStatuses(statuses.get(guesses.length)!, currentGuess, charObj)
        setStoredCharObj(charObj)

        return addGameStats([...guesses, currentGuess])
      } else if (
        isUndefined(statuses.get(guesses.length)) ||
        containsUndefined(statuses.get(guesses.length)!)
      ) {
        setStatuses(
          getGuessStatuses(
            guesses.length,
            solution,
            [...guesses, currentGuess],
            statuses,
            getStoredGameDate(),
            isHiddenLetterMode
          )
        )
        setStoredStatuses(statuses)

        setKeyStatuses(statuses.get(guesses.length)!, currentGuess, charObj)
        setStoredCharObj(charObj)

        aprilFools(getStoredGameDate())

        //i really really want to remove this but somehow without the onEnter the animation just doesn't render properly.
        //if you see this, please laugh and understand my pain. I tried, I seriously tried. If you do find a fix without this sh*t
        //please reachout using the contact us section
        onEnter()
        //the return is just here because of the onEnter above. it's meant to stop the process to avoid duplication of GA
        return
      }

      if (isCoSpotle()) {
        if (
          getStoredParallelMode() ||
          coSpotleGuesses.length === guesses.length
        ) {
          addGuess(username, currentGuess, guesses.length)
          setMyTurn(false)
          setStoredMyTurn(false)
        }
      }
      recordGuessGaEvent('game_guess', currentGuess, guesses.length + 1)

      if (!wasAprilFools) {
        setKeyStatuses(statuses.get(guesses.length)!, currentGuess, charObj)
      }

      if (
        guesses.length === MAX_CHALLENGES - 1 &&
        !(isCoSpotle() && getStoredPlainCoSpotle())
      ) {
        return addGameStats([...guesses, currentGuess])
      }
    }
  }

  return (
    <div>
      <div id="ezoic-pub-ad-placeholder-101"> </div>
      <div
        className="flex h-full flex-col"
        style={
          isFireworks && !isUnlimitedMode
            ? {
                backgroundImage:
                  'url(' + getSpecialBackground(getStoredGameDate()) + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '50% -9%',
              } //horizontal / vertical
            : {}
        }
      >
        <div
          className="flex h-full flex-col"
          style={
            isFireworks && !isUnlimitedMode
              ? {
                  backgroundImage:
                    'url(https://media2.giphy.com/media/O5QlTr51qI5RrEu7Bn/giphy.gif)',
                }
              : {}
          }
        >
          <Navbar
            setIsInfoModalOpen={setIsInfoModalOpen}
            setIsLanguageModalOpen={setIsLanguageModalOpen}
            setIsGamesModalOpen={setIsGamesModalOpen}
            setIsStatsModalOpen={setIsStatsModalOpen}
            setIsSettingsModalOpen={setIsSettingsModalOpen}
          />
          <div id="ezoic-pub-ad-placeholder-118"> </div>
          <div id="ezoic-pub-ad-placeholder-111"> </div>
          <div id="ezoic-pub-ad-placeholder-110"> </div>
          {getIsHorsle() && (
            <p className="text-center text-xs italic dark:text-white">
              {getShortnedDefinitionText(solution, 0)}
            </p>
          )}
          {isCoSpotle() && !getIsShowSpotleBot() && (
            <p className="text-center text-xs italic dark:text-white">
              {opponent + ': ' + getFlagEmoji(getStoredOpponentCountry())}
            </p>
          )}
          {isCoSpotle() && !getIsShowSpotleBot() && (
            <div>
              {!getStoredParallelMode() && (
                <>
                  {myTurn && (
                    <p className="text-center text-xs italic text-green-500">
                      {getTranslation(TranslationKey.MY_TURN)}
                    </p>
                  )}
                  {!myTurn && (
                    <p className="text-center text-xs italic text-red-500">
                      {getTranslation(TranslationKey.NOT_MY_TURN)}
                    </p>
                  )}
                </>
              )}
              <CountdownSeconds
                isGameLost={isGameLost}
                setIsGameLost={setIsGameLost}
                isGameWon={isGameWon}
                setIsGameWon={setIsGameWon}
                solution={solution}
                showSuccessAlert={showSuccessAlert}
                guesses={guesses}
                setStats={setStats}
                stats={stats}
              />
              {!getStoredBotMode() && (
                <p className="text-center text-xs font-bold italic text-red-500 underline">
                  <a href="https://discord.gg/uvFg7ggW4B">
                    Bugs are expected. Found one? Join our discord and report
                    it.
                  </a>
                </p>
              )}
            </div>
          )}
          {getIsScalizzi() && (
            <div className="text-center text-lg font-bold dark:text-white">
              <CountUpMonths startDate={new Date(2022, 11, 11, 12)} />
              <br />
            </div>
          )}
          <div className="mx-auto flex w-full grow flex-col px-1 pt-2 pb-8 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
            {!getIsShowSpotleBot() && (
              <div>
                {isCoSpotle() && getStoredParallelMode() && (
                  <div className="columns-2">
                    <div className="flex grow flex-col justify-center pb-6 short:pb-2">
                      <Grid
                        guesses={guesses}
                        currentGuess={currentGuess}
                        isRevealing={isRevealing}
                        currentRowClassName={currentRowClass}
                        statuses={statuses!}
                        maskedStatuses={getEasyGuessStatuses(
                          getStoredGameDate(),
                          isGridHardMode,
                          solution.length,
                          isHiddenLetterMode
                        )}
                        solution={solution}
                      />
                    </div>
                    <div className="flex grow flex-col justify-center pb-6 short:pb-2">
                      <Grid
                        guesses={
                          !isRevealing && (isGameLost || isGameWon)
                            ? coSpotleGuesses
                            : []
                        }
                        currentGuess={''}
                        isRevealing={false}
                        currentRowClassName={''}
                        statuses={coSpotleStatuses!}
                        maskedStatuses={getEasyGuessStatuses(
                          getStoredGameDate(),
                          isGridHardMode,
                          solution.length,
                          isHiddenLetterMode
                        )}
                        solution={solution}
                      />
                    </div>
                  </div>
                )}
                {!getStoredParallelMode() && (
                  <div className="flex grow flex-col justify-center pb-6 short:pb-2">
                    <Grid
                      guesses={guesses}
                      currentGuess={currentGuess}
                      isRevealing={isRevealing}
                      currentRowClassName={currentRowClass}
                      statuses={statuses!}
                      maskedStatuses={getEasyGuessStatuses(
                        getStoredGameDate(),
                        isGridHardMode,
                        solution.length,
                        isHiddenLetterMode
                      )}
                      solution={solution}
                    />
                  </div>
                )}
                <Keyboard
                  onChar={onChar}
                  onDelete={onDelete}
                  onEnter={onEnter}
                  isRevealing={
                    isRevealing ||
                    isCoSpotleGameModalOpen ||
                    isCustomGameModalOpen
                  }
                  charObj={charObj}
                  solution={solution}
                />
              </div>
            )}
            <InfoModal
              isOpen={isInfoModalOpen}
              handleClose={() => setIsInfoModalOpen(false)}
              isNormalStatuses={isNormalStatuses}
              handleIsNormalStatuses={handleIsNormalStatuses}
            />
            <LanguageModal
              isOpen={isLanguageModalOpen}
              handleClose={() => setIsLanguageModalOpen(false)}
            />
            <GamesModal
              isOpen={isGamesModalOpen}
              handleClose={() => setIsGamesModalOpen(false)}
              pageNumber={pageNumber}
              handleDecPageNumber={() => {
                setPageNumber(pageNumber - 1)
              }}
              handleIncPageNumber={() => {
                setPageNumber(pageNumber + 1)
              }}
              pageType={pageType}
              handleBlankPageType={() => setPageType(MODES.NONE)}
              handleToDailyPageType={() => setPageType(MODES.DAILY)}
              handleToUnlimitedPageType={() => setPageType(MODES.UNLIMITED)}
              handleToMultiplayerPageType={() => setPageType(MODES.MULTIPLAYER)}
              letterMode={letterMode}
              handleToFiveLetterMode={() => setLetterMode(LETTERS.FIVE_LETTER)}
              handleToSixLetterMode={() => setLetterMode(LETTERS.SIX_LETTER)}
              handleBlankLetterMode={() => setLetterMode(LETTERS.NONE)}
            />
            <ResetModal
              isOpen={isResetModalOpen}
              handleClose={() => setIsResetModalOpen(false)}
              setStats={setStats}
              onEnter={onEnter}
            />
            <GameSettingsModal
              isOpen={isGameSettingsModalOpen}
              handleClose={() => setIsGameSettingsModalOpen(false)}
              isHardMode={isHardMode}
              handleHardMode={handleHardMode}
              isGridHardMode={isGridHardMode}
              handleGridHardMode={handleGridHardMode}
              isHiddenLetterMode={isHiddenLetterMode}
              handleHiddenLetterMode={handleHiddenLetterMode}
              getIsShowSpotleBot={getIsShowSpotleBot}
              isBotAssistedMode={isBotAssistedMode}
              handleBotAssistedMode={handleBotAssistedMode}
              isRandomMode={isRandomMode}
              handleRandomMode={handleRandomMode}
              isSpyfallMode={isSpyfallMode}
              handleSpyfallMode={handleSpyfallMode}
              getNumberOfGames={getAppNumberOfGames}
              handleIncNumberOfGames={handleIncNumberOfGames}
              handleDecNumberOfGames={handleDecNumberOfGames}
              getNumberOfRows={getNumberOfRows}
              handleIncNumberOfRows={handleIncNumberOfRows}
              handleDecNumberOfRows={handleDecNumberOfRows}
              onResetGame={onResetGame}
            />
            <ThemeSettingsModal
              isOpen={isThemeSettingsModalOpen}
              handleClose={() => setIsThemeSettingsModalOpen(false)}
              isDarkMode={isDarkMode}
              handleDarkMode={handleDarkMode}
              isHighContrastMode={isHighContrastMode}
              handleHighContrastMode={handleHighContrastMode}
            />
            <SettingsModal
              isOpen={isSettingsModalOpen}
              handleClose={() => setIsSettingsModalOpen(false)}
              setIsGameSettingsModalOpen={setIsGameSettingsModalOpen}
              setIsThemeSettingsModalOpen={setIsThemeSettingsModalOpen}
              setIsGamesModalOpen={setIsGamesModalOpen}
              setIsLanguageModalOpen={setIsLanguageModalOpen}
            />
            <AboutUsModal
              isOpen={isContactInfoModalOpen}
              handleClose={() => setIsContactInfoModalOpen(false)}
            />
            <NewGameModeModal
              isOpen={
                isNewGameModeInfoModalOpen &&
                !isInfoModalOpen &&
                !isAnnouncementsModalOpen
              }
              handleClose={() => setIsNewGameModeInfoModalOpen(false)}
            />
            <AnnouncementsModal
              isOpen={isAnnouncementsModalOpen && !isInfoModalOpen}
              handleClose={() => setIsAnnouncementsModalOpen(false)}
            />
            <StatsModal
              isOpen={isStatsModalOpen}
              handleClose={() => setIsStatsModalOpen(false)}
              guesses={guesses}
              gameStats={stats}
              isGameLost={isGameLost}
              isGameWon={isGameWon}
              handleShareToClipboard={() =>
                showSuccessAlert(
                  getTranslation(TranslationKey.GAME_COPIED_MESSAGE)
                )
              }
              handleShareFailure={() =>
                showErrorAlert(
                  getTranslation(TranslationKey.SHARE_FAILURE_TEXT),
                  {
                    durationMs: LONG_ALERT_TIME_MS,
                  }
                )
              }
              handleMigrateStatsButton={() => {
                setIsStatsModalOpen(false)
                setIsMigrateStatsModalOpen(true)
              }}
              isHardMode={isHardMode}
              isGridHardMode={isGridHardMode}
              isHiddenLetterMode={isHiddenLetterMode}
              isDarkMode={isDarkMode}
              isHighContrastMode={isHighContrastMode}
              numberOfGuessesMade={guesses.length}
              statuses={statuses}
              today={getStoredGameDate()}
              onResetGame={isCoSpotle() ? resetCoSpotleGame : onResetGame}
              setShowSpotleBot={setShowSpotleBot}
              getIsShowSpotleBot={getIsShowSpotleBot}
              setIsResetModalOpen={setIsResetModalOpen}
              isButtonEnabled={isButtonEnabled}
              handleButtonDisable={() => setIsButtonEnabled(false)}
            />
            <CoSpotleGameModal
              isOpen={isCoSpotleGameModalOpen}
              handleClose={() => {
                if (getStoredBotMode() && getStoredOpponent() !== '') {
                  setIsCoSpotleGameModalOpen(false)
                }
              }}
              onResetGame={resetCoSpotleGame}
              isHardMode={isHardMode}
              handleHardMode={handleHardMode}
              isGridHardMode={isGridHardMode}
              handleGridHardMode={handleGridHardMode}
              isHiddenLetterMode={isHiddenLetterMode}
              handleHiddenLetterMode={handleHiddenLetterMode}
              handleCustomUsername={handleCustomUsername}
              path={props.path}
              showSuccessAlert={showSuccessAlert}
              showErrorAlert={showErrorAlert}
            />
            <CustomGameModal
              isOpen={isCustomGameModalOpen}
              handleClose={() => setIsCustomGameModalOpen(false)}
              handleCustomSolution={handleCustomSolution}
              path={props.path}
              showSuccessAlert={showSuccessAlert}
            />
            <MigrateStatsModal
              isOpen={isMigrateStatsModalOpen}
              handleClose={() => setIsMigrateStatsModalOpen(false)}
            />
            <AlertContainer />
          </div>
          {getIsShowSpotleBot() && (
            <>
              <SpotleBot
                today={getStoredGameDate(true)}
                hardMode={isHardMode}
                gridHardMode={isGridHardMode}
                hiddenLetterMode={isHiddenLetterMode}
                playerGuesses={guesses}
                playerStatuses={statuses!}
                handleShareToClipboard={() =>
                  showSuccessAlert(
                    getTranslation(TranslationKey.GAME_COPIED_MESSAGE)
                  )
                }
                handleShareFailure={() =>
                  showErrorAlert(
                    getTranslation(TranslationKey.SHARE_FAILURE_TEXT),
                    {
                      durationMs: LONG_ALERT_TIME_MS,
                    }
                  )
                }
                handleBotSwitch={() => {
                  showSuccessAlert('', {
                    durationMs: 500,
                  })
                }}
                botPageNumber={botPageNumber}
                handleDecBotPageNumber={() => {
                  setBotPageNumber(botPageNumber - 1)
                }}
                handleIncBotPageNumber={() => {
                  setBotPageNumber(botPageNumber + 1)
                }}
                onOpenSolutionsPage={() => {
                  setSolutionsPageNumber(0)
                  setShowSolutionsRemaining(true)
                }}
              />

              <SolutionsModal
                handleDecSolutionsPageNumber={() =>
                  setSolutionsPageNumber(solutionsPageNumber - 1)
                }
                handleIncSolutionsPageNumber={() =>
                  setSolutionsPageNumber(solutionsPageNumber + 1)
                }
                onClose={() => setShowSolutionsRemaining(false)}
                solutions={getStoredCurrentRemainingSolutions()}
                solutionsPageNumber={solutionsPageNumber}
                isOpen={showSolutionsRemaining}
              />
            </>
          )}

          <Footbar
            setIsContactInfoModalOnly={setIsContactInfoModalOpen}
            showDefinition={
              (isGameWon || isGameLost) &&
              getLanguage() === Language.ENGLISH &&
              !getIsHorsle() &&
              !getIsSpeedy() &&
              !isRevealing
            }
            solution={solution}
          />
        </div>
      </div>
      <div id="ezoic-pub-ad-placeholder-102"> </div>
    </div>
  )
}

export default App
